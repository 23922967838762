import React from 'react'
import {Link} from 'gatsby'
import { BiMenu } from "react-icons/bi";
import PageLinks from './PageLinks';
export default function Navbar({toggleSidebar}) {
    return (
        <navbar>
            <button type='button' onClick={toggleSidebar} className='toggle-btn py-2'>
                <BiMenu />
            </button>
            <div className="nav-links">
                {PageLinks.map((link)=>{
                    return <Link
                    key={link.id}
                    to={link.link}
                    className="py-2  text-xl px-2 text-gray-100 font-semibold cursor-pointer 
                            uppercase hover:text-pink-400 duration-300"
                     >
                        {link.text}
                    </Link>
                })}
            </div>
    </navbar>
    )
}
