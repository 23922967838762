import React, {useState} from 'react'
import Header from './Header/Header'
import SideBar from './SideBar'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
import "../assets/css/main.css"
export default function Layout({children}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar =()=>{
        setIsOpen(!isOpen);
    }
    return (
        <>
            {/* <Helmet>
                <title>Alejandra López Noriega</title>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" 
                integrity="sha512-NhSC1YmyruXifcj/KFRWoC561YpHpc5Jtzgvbuzx5VozKpWvQ+4nXhPdFgmx8xqexRcpAglTj9sIBWINXa8x5w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            </Helmet> */}
            <Header toggleSidebar={toggleSidebar}/>
            <SideBar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
            {children} 
            <Footer/>   
        </>
    )
}
