import React from 'react'
import logo from '../../images/logo.svg'
import { Link } from "gatsby"

export default function Logotipo() {
    return (
        <Link to={'/'} className="md:h-150 md:w-120 self-center mx-2">
            <img className='h-16 self-center' src={logo} alt=""/>
        </Link>  
    )
}
