import React from 'react'
import {FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaYoutubeSquare} from 'react-icons/fa'
export default function SocialMedia() {
    return (
        <div className="py-1 pr-20 text-gray-300 font-semibold 
                    uppercase duration-300 social-media flex flex-row">
            
            <a className="px-1 text-3xl cursor-pointer facebook 
                          duration-300"
                href='https://www.facebook.com/AlejandraLopezNoriega.org'
                target="_blank"          
            >
                <FaFacebookSquare />    
            </a>
            <a className="px-1 text-3xl cursor-pointer twitter 
                          duration-300"
                href='https://twitter.com/alejandralopesn'
                target="_blank"
            >
                <FaTwitterSquare />    
            </a>
            <a className="px-1 text-3xl cursor-pointer instagram 
                          duration-300"
                href='https://www.instagram.com/alejandralopeznoriega2018/'
                target="_blank"
            >
                <FaInstagramSquare />    
            </a>
            <a className="px-1 text-3xl cursor-pointer youtube 
                          duration-300"
                href='https://www.youtube.com/user/alejandralopezn'
                target="_blank"
            >
                <FaYoutubeSquare />    
            </a>
        </div>
    )
}
