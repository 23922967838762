const PageLinks =[
    {
        id:1,
        text:'Propuestas',
        link:'/#iniciativas'
    },
    {
        id:2,
        text:'Noticias',
        link:'/blognoticias'
    },
    {
        id:3,
        text:'Contacto',
        link:'/#contacto'
    },

]

export default PageLinks