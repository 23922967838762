import React from 'react'
import {Link} from 'gatsby'
import PageLinks from './Header/PageLinks'
import { FaTimes } from 'react-icons/fa'
import {FaFacebookSquare, FaTwitterSquare, FaInstagramSquare, FaYoutubeSquare} from 'react-icons/fa'

export default function SideBar({isOpen, toggleSidebar}) {

    return (
        <aside className={isOpen ? 'sidebar show-sidebar' : 'sidebar'}>
            <button className='close-btn' onClick={toggleSidebar}>
                <FaTimes />
            </button>
            <div className="side-container">
                <ul className={isOpen ? "sidebar-links" : null}>
                    {PageLinks.map((link)=>{
                     return(    
                        
                        <li key={link.id}>
                            <Link to={link.link} onClick={toggleSidebar}> {link.text} </Link>
                        </li>
                     )    
                    })}
                </ul>
                <ul className={isOpen ? "social-links sidebar-icons": null}>
                    <li><a className="social-link" target="_blank" href='https://www.facebook.com/AlejandraLopezNoriega.org'><FaFacebookSquare /> </a></li>
                    <li><a className="social-link" target="_blank" href='https://twitter.com/alejandralopesn'><FaTwitterSquare /> </a></li>
                    <li><a className="social-link" target="_blank" href='https://www.instagram.com/alejandralopeznoriega2018/'><FaInstagramSquare /></a></li>
                    <li><a className="social-link" target="_blank" href='https://www.youtube.com/user/alejandralopezn'><FaYoutubeSquare /> </a></li> 
                </ul>
            </div>
        </aside>
    )
}
