import React from 'react'
import { Link } from "gatsby"
import SocialMedia from './SocialMedia'
import Navbar from './Navbar'
import Logotipo from './Logotipo'
export default function Header({toggleSidebar}) {
    return (
        <div className='grid grid-flow-col md:grid-flow-row'>
            <div className="h-18 top-navbar py-3 md:px-14 w-full flex flex-col md:flex-row 
            items-center bg-white justify-between">
                <Logotipo /> 
                <div className='hidden md:block'>
                    <SocialMedia />
                </div> 
            </div>
            <div className="bottom-navbar py-1 px-14 w-full flex flex-col md:flex-row 
            items-center justify-between">
                <Link to={'/'} className="text-xl text-white hidden md:block px-5 py-1 text-white cursor-pointer
                        font-bold uppercase">
                    Inicio
                </Link>
                <Navbar toggleSidebar={toggleSidebar}/>
            </div>   
        </div>
    )
}
